html {
  scroll-behavior: smooth;
  
}

$theme-colors: (
  "primary": #233067,
  "secondary": #e9f2f2,
  "info": #41ccc4,
);
$carousel-indicator-active-bg: #233067;
$carousel-indicator-height: 5px;
$carousel-indicator-bg: #41ccc4;
$carousel-indicator-opacity: 1;
$border-width: 0px;
$component-active-bg: #233067;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 23px,
  4: 4px,
  5: 5px,
);
$input-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);

@import "~bootstrap/scss/bootstrap";
body {
  font-family: "Ubuntu", sans-serif;
  
  
}

.hide-overflow {
  overflow-x: hidden;
}


.sidenav-pannel {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-left: -300px;
  width: 250px;
  min-height: 100vh;
  height: 100%;
  position: fixed;
  transition: 0.4s;
  background-color: #233067;
}
.sidenav-text-position {
  margin-top: 140px;
}
#menu-btn:focus {
  box-shadow: 0 0 0 0.25rem #233067;
}

.error-style {
  color: #ff0000;
  position: absolute;
  font-size: 12px;
}

.z-index-navbar {
  z-index: 10000;
}

.pannel-logo-position {
  margin-top: 140px;
}

.sidenav-pannel.active {
  margin-left: 0px;
}

.navigation-link {
  font-size: 18px;
}

.navigation-link:active,
.navigation-link:focus,
.navigation-link:hover {
  background-color: #ffffff26;
}

.my-container {
  transition: 0.4s;
  width: 100%;
}

.pannel-menu-btn:hover {
  background-color: rgba(44, 43, 43, 0.15);
}

.my-container.sidebar-active {
  margin-left: 270px;
  @media (min-width: 992px) {
    width: auto;
  }
}

.active-nav {
  margin-left: 0;
}

.active-cont {
  margin-left: 250px;
}

.carousel-indicators [data-bs-target]:not(.active) {
  background-color: $carousel-indicator-bg;
}

.carousel-indicators {
  bottom: -50px !important;
}

.roboto {
  font-family: "Roboto", sans-serif;
}

.custom-card {
  background-color: #fff;
  border-radius: 23px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  align-items: center;
}

.custom-card-body {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.features-card-bar {
  width: 3px;
  min-height: 10px;

  min-width: 3px;
  max-width: 3px;
}

.smaller-font {
  font-size: 18px;
}

.partner-background {
  background-image: url("img/bg-emepag.png");
  border-radius: 23px;
}

.partner-img {
  min-width: 150px;
  min-height: 150px;
}

.fees-container {
  min-height: 300px;
}

.fees-card {
  width: 95%;
  height: 70%;
  border-radius: 23px;
}

.justifying {
  text-align: justify;
  text-justify: inter-word;
}

.position-img {
  position: relative;
  bottom: 90px;
}

.pannel-area-border {
  border-radius: 13px;
}

.message-border {
  border-radius: 23px;
}

.footer-logo {
  max-width: 120px;
}
.white-space {
  min-height: 100px;
}
.bg-size {
  max-height: 280px;
}

@media (max-width: 768px) {
  .margin-signin-banner {
    margin-top: -30px;
  }
}

@media (min-width: 768px) {
  .form-size {
    width: 60%;
  }
}

.bigger-font {
  font-size: 60px;
}

@media (max-width: 768px) {
  .bg-size {
    max-height: 300px;
  }
}

.footer-size {
  min-height: 300px;
}
.nounderline {
  text-decoration: none;
}

.even-smaller-font {
  font-size: 14px;
}

@media (min-width: 768px) {
  .margin-form {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media (min-width: 768px) {
  .position-img {
    position: relative;
    bottom: 100px;
  }
}
@media (min-width: 992px) {
  .position-img {
    position: relative;
    bottom: 160px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .position-img {
    position: relative;
    bottom: 180px;
  }
}

.position-signup-img {
  position: relative;
  bottom: 90px;
}

@media (max-width: 576px) {
  .signup-text {
    margin-top: -90px;
  }
}

@media (min-width: 768px) {
  .position-signup-img {
    position: relative;
    bottom: 100px;
  }
}
@media (min-width: 992px) {
  .position-signup-img {
    position: relative;
    bottom: 160px;
    width: 100%;
  }
}
@media (min-width: 1400px) {
  .position-signup-img {
    position: relative;
    bottom: 180px;
  }
}

@media (min-width: 768px) {
  .showcase-img {
    position: relative;
    right: 50px;
  }
}

@media (max-width: 992px) {
  .banner-top-margin {
    margin-top: 120px;
  }
}

@media (min-width: 992px) {
  .banner-top-margin {
    margin-top: 150px;
  }
}

@media (max-width: 576px) {
  .margin-creditcard {
    margin-top: -20px;
  }

  .fees-card {
    width: 100%;
  }
}

@media (min-width: 768px) {
  .fees-card {
    width: 100%;
  }
}
